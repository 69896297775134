import {
    Button,
    Checkbox,
    InputAdornment,
    styled,
    TextField,
    Typography,
} from "@mui/material";
import React from "react";
import logo from "../Assets/Images/logo.png";
import { FaRegCircle, FaUser } from "react-icons/fa";
import { FaCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { IoIosLock } from "react-icons/io";
import { MdEmail } from "react-icons/md";
import { MdOutlineEmail } from "react-icons/md";

const VerticalDivider = styled("div")(({ theme }) => ({
    width: 3,
    height: 20,
    margin: theme.spacing(0.5, 0.5),
    backgroundColor: theme.palette.divider,
}));

export default function LoginContent({
    setUsername,
    username,
    isLoading,
    setErrors,
    errors,
    setPassword,
    password,
    handleLoginSubmit,
}) {
    const navigate = useNavigate();
    return (
        <div
            style={{
                display: "flex",
                height: "100vh",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <img
                className="loginPageMainLogo"
                onClick={() => navigate("/")}
                src={logo}
            />
            <Typography
                sx={{
                    fontWeight: "500",
                    fontSize: "26px",
                    letterSpacing: "1px",
                }}
                color="primary"
            >
                WELCOME BACK!
            </Typography>
            <Typography
                sx={{
                    fontWeight: "400",
                    fontSize: "22px",
                    color: "#6D6E71",
                    letterSpacing: "1px",
                }}
            >
                Please Login
            </Typography>

            <form
                onSubmit={handleLoginSubmit}
                style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                }}
            >
                <TextField
                    value={username}
                    placeholder="Username"
                    autoComplete="off"
                    sx={{ marginTop: "2rem" }}
                    error={!!errors.username}
                    className="custom-text-field"
                    onChange={(e) => {
                        setErrors((prev) => ({ ...prev, username: "" }));
                        setUsername(e.target.value);
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <MdOutlineEmail
                                    size={20}
                                    style={{
                                        marginLeft: "4px",
                                        marginRight: "6px",
                                    }}
                                />
                                <VerticalDivider />
                            </InputAdornment>
                        ),
                    }}
                />
                <p className="errormessage">{errors.username}</p>

                <TextField
                    className="custom-text-field"
                    placeholder="Password"
                    value={password}
                    type="password"
                    error={!!errors.password}
                    onChange={(e) => {
                        setErrors((prev) => ({ ...prev, password: "" }));
                        setPassword(e.target.value);
                    }}
                    sx={{ marginTop: "1rem" }}
                    autoComplete="off"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <IoIosLock
                                    size={20}
                                    style={{
                                        fontSize: "1.2rem",
                                        marginRight: "5px",
                                        marginLeft: "4px",
                                    }}
                                />
                                <VerticalDivider />
                            </InputAdornment>
                        ),
                    }}
                />
                <p className="errormessage">{errors.password}</p>

                <Button className="loginBtn" type="submit" disabled={isLoading}>
                    {isLoading ? "Logging in..." : "LOGIN"}
                </Button>
            </form>

            <div className="bottomCreateAccParent">
                {/* <div>
                    <Checkbox
                        id="rememberme"
                        sx={{ paddingInline: "0px" }}
                        size="small"
                        icon={<FaRegCircle />}
                        checkedIcon={<FaCircle />}
                    />
                    <label htmlFor="rememberme" className="loginbottomoptions">
                        &nbsp;Remember me
                    </label>
                </div> */}

                <div>
                    <span
                        onClick={() => navigate("/register")}
                        className="loginbottomoptions"
                    >
                        Create an account?{" "}
                        <span className="loginbottomcoloredoptions">
                            Register
                        </span>
                    </span>
                </div>
                <div>
                    <span
                        onClick={() => navigate("/forgot-password")}
                        className="loginbottomoptions"
                    >
                        Forgot{" "}
                        <span className="loginbottomcoloredoptions">
                            Username
                        </span>{" "}
                        /{" "}
                        <span className="loginbottomcoloredoptions">
                            Password
                        </span>
                    </span>
                </div>
            </div>
        </div>
    );
}
