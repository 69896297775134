import React, { useEffect, useState } from "react";
import HomeNav from "../Component/HomeNav";
import "./home.css";
import { Button, CircularProgress } from "@mui/material";
import { AiFillPlusCircle } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../Constant_key";
import { toast } from "react-toastify";

export default function PartSearch() {
    const navigate = useNavigate();
    const [searchData, setsearchData] = useState([]);
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        getAllSearchParts();
    }, []);

    const getAllSearchParts = () => {
        setLoader(true);
        const token = localStorage.getItem("token");

        axios
            .post(`${baseUrl}api/getPartSearchHistory`, undefined, {
                headers: { Authorization: `bearer ${token}` },
            })
            .then((res) => {
                if (res.data?.data) {
                    setsearchData(res.data?.data);
                    setLoader(false);
                }
            })
            .catch((err) => {
                if (err?.response?.status == 401) {
                    toast.error(err?.response?.data?.message);
                    setLoader(false);
                }
                if (err?.response?.status == 404) {
                    setsearchData([]);
                }
            })
            .finally(() => setLoader(false));
    };

    return (
        <div>
            <HomeNav />

            <div style={{ display: "flex", justifyContent: "center" }}>
                <div className="mainnavbar">
                    <div style={{ overflowX: "auto" }}>
                        <table
                            style={{
                                width: "100%",
                                minWidth: "800px",
                                borderCollapse: "separate",
                                borderSpacing: "0 15px",
                            }}
                        >
                            <thead>
                                <tr>
                                    <th className="parttrackertableheading">
                                        Part Number
                                    </th>
                                    <th className="parttrackertableheading">
                                        Manufacturer
                                    </th>
                                    <th className="parttrackertableheading">
                                        Searched On
                                    </th>
                                    <th style={{ textAlign: "right" }}>
                                        <div>
                                            <Button
                                                sx={{ fontSize: "11px" }}
                                                variant="outlined"
                                                onClick={() => navigate("/")}
                                            >
                                                <AiFillPlusCircle
                                                    fontSize={16}
                                                    style={{
                                                        marginRight: "10px",
                                                        color: "black",
                                                    }}
                                                />
                                                Search Part
                                            </Button>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {loader ? (
                                    <tr>
                                        <td
                                            style={{ textAlign: "center" }}
                                            colSpan={4}
                                        >
                                            <CircularProgress />
                                        </td>
                                    </tr>
                                ) : searchData.length > 0 ? (
                                    searchData.map((item) => (
                                        <tr
                                            key={item?.id}
                                        >
                                            <td
                                                style={{
                                                    borderTopLeftRadius: "8px",
                                                    borderBottomLeftRadius:
                                                        "8px",
                                                    borderLeft:
                                                        "1px solid #1f80c0",
                                                }}
                                                className="parttrackercellStyle"
                                            >
                                                {item?.part_number}
                                            </td>
                                            <td className="parttrackercellStyle">
                                                {item?.manufacturer}
                                            </td>
                                            <td className="parttrackercellStyle">
                                                {item?.search_time}
                                            </td>
                                            <td
                                                className="parttrackercellStyle"
                                                style={{
                                                    borderRight:
                                                        "1px solid #1f80c0",
                                                    borderTopRightRadius: "8px",
                                                    borderBottomRightRadius:
                                                        "8px",
                                                }}
                                            ></td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td
                                            style={{ textAlign: "center" }}
                                            className="parttrackercellStyle"
                                            colSpan={4}
                                        >
                                            No parts are being tracked or wished
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
}
