import React, { useEffect, useState } from "react";
import HomeNav from "../Component/HomeNav";
import ProductModal from "./ProductModal";
import {
    Button,
    Checkbox,
    Container,
    Divider,
    Grid,
    Typography,
} from "@mui/material";
import { IoChevronBackOutline } from "react-icons/io5";
import CartHeading from "../Component/CartHeading";
import { HiMiniShoppingCart } from "react-icons/hi2";
import { FaShippingFast } from "react-icons/fa";
import CartItem from "../Component/CartItem";
import { GrLinkNext } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
    deleteCartItem,
    getCartItems,
    updateCartItem,
} from "../Store/cartSlice";
import emptycart from '../Assets/Images/emptycart.png'

export default function ShoppingCart() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const cartItems = useSelector((state) => state?.cartReducer?.items || []);

    const handleDeleteCartItem = (item) => {
        const value = {
            part_number: item.part_number,
            cartId: cartItems?.cart?.cart_id,
        };
        dispatch(deleteCartItem(value));
    };

    const totalPrice = cartItems?.items?.reduce((total, item) => {
        // Convert price to a number and calculate the total
        const price = parseFloat(item.price);
        return total + (price * item.quantity);
    }, 0); // Initial value of total is 0

    useEffect(() => {
        dispatch(getCartItems());
    }, []);

    const handleUpdateCartItem = (price, partNumber, newQuantity) => {
        const value = {
            quantity: parseInt(newQuantity),
            price: price,
        };
        const cartId = cartItems?.cart?.cart_id;
        dispatch(updateCartItem({ value, cartId, partNumber }));
    };

    const handleCheckoutBtn = () => {
        navigate("/order-summary", {state: totalPrice?.toFixed(2)});
    };

    return (
        <div>
            <HomeNav />
            <div style={{ display: "flex", justifyContent: "center" }}>
                <div className="mainnavbar">
                    <div
                        style={{
                            marginTop: "1rem",
                            position: 'relative',
                            zIndex: 999,
                            display: "flex",
                            alignItems: "center",
                        }}
                    >
                        <IoChevronBackOutline
                            onClick={(e) => {
                                e.stopPropagation(); // Prevent other handlers from interfering
                                navigate("/");
                            }}                        
                            style={{ cursor: "pointer" }}
                            fontSize={20}
                        />
                        <Typography
                            onClick={(e) => {
                                e.stopPropagation(); // Prevent other handlers from interfering
                                navigate("/");
                            }}                        
                        
                            sx={{
                                fontWeight: 600,
                                cursor: "pointer",
                                marginLeft: "10px",
                                fontSize: "18px",
                            }}
                        >
                            Continue Shopping
                        </Typography>
                    </div>

                    <Divider sx={{ marginTop: "10px" }} />

                    <div>
                        <Grid container spacing={4}>
                            <Grid item xs={12} md={12}>
                                <div style={{ marginTop: "1rem" }}>
                                    <CartHeading
                                        icon={
                                            <HiMiniShoppingCart fontSize={14} />
                                        }
                                        heading="Shopping Cart"
                                        caption={`You have ${
                                            cartItems?.items?.length || 0
                                        } item in your cart`}
                                    />
                                </div>
                                <div style={{ marginTop: "2rem" }}>
                                    {
                                        cartItems?.items?.length > 0 ?

                                        cartItems?.items?.map((item) => (
                                            <CartItem
                                                key={item?.part_number}
                                                handleDeleteCartItem={
                                                    handleDeleteCartItem
                                                }
                                                handleUpdateCartItem={handleUpdateCartItem}
                                                item={item}
                                            />
                                        )) :
                                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                                            <img style={{width: '200px', objectFit: 'contain'}} src={emptycart}/>
                                        </div>
                                    }
                                </div>
                            </Grid>
                        </Grid>

                        <Divider sx={{ marginTop: "1rem" }} />

                        <Grid container sx={{ marginTop: "10px" }} spacing={2}>
                            <Grid item md={6}>
                                <span style={{ fontSize: "10px" }}>
                                    By submitting your order you agree to
                                    these&nbsp;
                                    <span
                                        style={{
                                            color: "#1F80C0",
                                            fontWeight: 500,
                                        }}
                                    >
                                        terms and conditions
                                    </span>
                                    . **All items are non-cancellable and
                                    non-returnable.
                                </span>
                            </Grid>
                            <Grid item md={6}>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "end",
                                    }}
                                >
                                    {cartItems?.items?.length > 0 && (
                                        <Button
                                            onClick={() => handleCheckoutBtn()}
                                            variant="contained"
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            PLACE ORDER &nbsp; <GrLinkNext />
                                        </Button>
                                    )}
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    {/* </Container> */}
                </div>
            </div>
        </div>
    );
}
