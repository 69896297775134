import * as React from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepConnector, {
    stepConnectorClasses,
} from "@mui/material/StepConnector";
import { FaCheck } from "react-icons/fa6";

const activeColor = "linear-gradient(to right, #16ace3, #1f80c0)";

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
    color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
    ...(ownerState.active && {
        color: "#784af4",
    }),
    "& .QontoStepIcon-completedIcon": {
        color: "#784af4",
        zIndex: 1,
        fontSize: 18,
    },
    "& .QontoStepIcon-circle": {
        width: 8,
        height: 8,
        borderRadius: "50%",
        backgroundColor: "currentColor",
    },
}));

function QontoStepIcon(props) {
    const { active, completed, className } = props;

    return (
        <QontoStepIconRoot ownerState={{ active }} className={className}>
            {completed ? <FaCheck /> : <div className="QontoStepIcon-circle" />}
        </QontoStepIconRoot>
    );
}

QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 9,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage: "linear-gradient(to right, #16ace3, #1f80c0)",
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage: "linear-gradient(to right, #16ace3, #1f80c0)",
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor:
            theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
        borderRadius: 1,
    },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
    backgroundColor:
        theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 20,
    height: 20,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    ...(ownerState.active && {
        backgroundImage: "linear-gradient(to right, #16ace3, #1f80c0)",
        boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    }),
    ...(ownerState.completed && {
        backgroundImage: "linear-gradient(to right, #16ace3, #1f80c0)",
    }),
    ...(ownerState.active && {
        backgroundImage: activeColor,
        boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    }),
    ...(ownerState.completed && {
        backgroundImage: activeColor,
    }),
}));

function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
        1: "1",
        2: "2",
        3: "3",
        4: "4",
        5: "5",
    };

    return (
        <ColorlibStepIconRoot
            ownerState={{ completed, active }}
            className={className}
            sx={{ fontSize: "10px" }}
        >
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}

ColorlibStepIcon.propTypes = {
    active: PropTypes.bool,
    className: PropTypes.string,
    completed: PropTypes.bool,
    icon: PropTypes.node,
};

const steps = ["Address", "Shipping", "Payment", "Review"];

export default function CustomizedSteppers({ status }) {
    const getActiveStep = (status) => {
        if (status <= 1) return status;
        // if (status === 2) return 1; // Keep Shipping active when status is 3
        return status; // Adjust for status 4 and 5
    };
    const activeStep = getActiveStep(status);

    return (
        // <Stack sx={{ width: "100%" }} spacing={2}>
        //     <Stepper
        //         alternativeLabel
        //         activeStep={activeStep}
        //         connector={<ColorlibConnector />}
        //     >
        //         {steps.map((label, index) => (
        //             <Step key={label}>
        //                 <StepLabel
        //                     StepIconComponent={ColorlibStepIcon}
        //                     sx={{
        //                         "& .MuiStepLabel-label": {
        //                             marginTop: "7px",
        //                             fontSize: "12px",
        //                             // color:
        //                             //     index <= status
        //                             //         ? "transparent"
        //                             //         : "inherit",
        //                             // backgroundImage:
        //                             //     index <= status ? activeColor : "none",
        //                             // backgroundClip: "text",
        //                             // WebkitBackgroundClip: "text",
        //                             // fontWeight:
        //                             //     index <= status ? "bold" : "normal",
        //                         },
        //                     }}
        //                 >
        //                     {label}
        //                 </StepLabel>
        //             </Step>
        //         ))}
        //     </Stepper>
        // </Stack>
        <Stack sx={{ width: "100%" }} spacing={2}>
            <Stepper
                alternativeLabel
                activeStep={activeStep}
                connector={<ColorlibConnector />}
            >
                {steps.map((label, index) => (
                    <Step key={label}>
                        <StepLabel
                            StepIconComponent={ColorlibStepIcon}
                            sx={{
                                "& .MuiStepLabel-label": {
                                    marginTop: "6px",
                                    fontSize: "12px",
                                },
                            }}
                        >
                            {label}
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Stack>
    );
}
