import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../Constant_key";

export const forgotPassword = createAsyncThunk(
    "auth/forgotPassword",
    async (value, { rejectWithValue }) => {
        try {
            const response = await axios.post(
                `${baseUrl}api/forgot-password`,
                value
            );
            return response.data;
        } catch (error) {
            if (error.response) {
                return rejectWithValue(error.response.data);
            }
            return rejectWithValue({
                message: "An unexpected error occurred. Please try again.",
            });
        }
    }
);

// Async thunk for login
export const login = createAsyncThunk(
    "auth/login",
    async (value, { rejectWithValue }) => {
        try {
            const response = await axios.post(`${baseUrl}api/login`, value);
            if (response.status == 201) {
                return rejectWithValue(response.data);
            }

            return response.data;
        } catch (error) {
            if (error.response) {
                return rejectWithValue(error.response.data);
            }
            return rejectWithValue({
                message: "An unexpected error occurred. Please try again.",
            });
        }
    }
);

const authSlice = createSlice({
    name: "auth",
    initialState: {
        user: null,
        isLoading: false,
        error: null,
        forgotPasswordSuccess: false,
    },
    reducers: {
        logout: (state) => {
            state.user = null;
            state.error = null;
            localStorage.removeItem("token");
            localStorage.removeItem("userData");
            localStorage.removeItem("isLogin");
        },

        resetForgotPasswordState: (state) => {
            state.forgotPasswordSuccess = false;
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(login.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(login.fulfilled, (state, action) => {
                state.isLoading = false;
                state.user = action.payload;
            })
            .addCase(login.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload || {
                    message: "An unexpected error occurred. Please try again.",
                };
            });
        builder
            .addCase(forgotPassword.pending, (state) => {
                state.isLoading = true;
                state.error = null;
                state.forgotPasswordSuccess = false;
            })
            .addCase(forgotPassword.fulfilled, (state) => {
                state.isLoading = false;
                state.forgotPasswordSuccess = true;
            })
            .addCase(forgotPassword.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload || {
                    message:
                        "Failed to send reset password link. Please try again.",
                };
            });
    },
});

export const { logout, resetForgotPasswordState } = authSlice.actions;
export default authSlice.reducer;
