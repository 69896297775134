import { BrowserRouter } from "react-router-dom";
import Routee from "./Routee";
import { createTheme, ThemeProvider, Box } from "@mui/material";
import { Provider } from "react-redux";
import { store } from "./Store";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

function App() {
    const customTheme = createTheme({
        palette: {
            primary: {
                main: "#1F80C0", // Fallback solid color
            },
            secondary: {
                main: "#6D6E71",
            },
            success: {
                main: "#ED1C24",
            },
        },
        components: {
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        "& .MuiOutlinedInput-input": {
                            padding: "6px 10px", // Adjust the padding to set height
                        },
                    },
                },
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        textTransform: "none",
                        paddingInline: "26px",
                        paddingBlock: "4px",
                        fontSize: "13px",
                        borderRadius: "4px", // Add this line
                        "&.MuiButton-containedPrimary": {
                            background:
                                "linear-gradient(to right, #16ACE3, #1F80C0)",
                            "&:hover": {
                                background:
                                    "linear-gradient(to right, #1498c9, #1b70a9)",
                            },
                        },
                        "&.MuiButton-outlinedPrimary": {
                            background:
                                "linear-gradient(to right, #16ACE3, #1F80C0)",
                            border: "none",
                            position: "relative",
                            zIndex: 1,
                            "&::before": {
                                content: '""',
                                position: "absolute",
                                top: 1,
                                left: 1,
                                right: 1,
                                bottom: 1,
                                background: "white",
                                zIndex: -1,
                                borderRadius: "3px", // Add this line
                            },
                            "&:hover": {
                                background:
                                    "linear-gradient(to right, #1498c9, #1b70a9)",
                                "&::before": {
                                    background: "white",
                                },
                            },
                        },
                    },
                },
            },
        },
    });

    return (
        <ThemeProvider theme={customTheme}>
            <BrowserRouter>
                <ToastContainer />
                <Routee />
            </BrowserRouter>
        </ThemeProvider>
    );
}

export default App;
