import React, { useEffect, useState } from "react";
import close from "../Assets/Images/Close.png";
import LoginContent from "./LoginContent";
import { useDispatch, useSelector } from "react-redux";
import {
    Button,
    Checkbox,
    InputAdornment,
    Dialog,
    DialogContent,
    DialogTitle,
    styled,
    TextField,
    Typography,
} from "@mui/material";
import { toast } from "react-toastify";
import { login } from "../Store/authSlice";
import logo from "../Assets/Images/logo.png";
import { FaRegCircle, FaUser } from "react-icons/fa";
import { FaCircle } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { IoIosLock } from "react-icons/io";
import { MdOutlineEmail } from "react-icons/md";

const VerticalDivider = styled("div")(({ theme }) => ({
    width: 3,
    height: 20,
    margin: theme.spacing(0.5, 0.5),
    backgroundColor: theme.palette.divider,
}));

export default function LoginModal({ handleClose, open, show, setShow }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isLoading, error, user } = useSelector((state) => state.auth);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [errors, setErrors] = useState({});

    useEffect(() => {
        if (user) {
            localStorage.setItem("userData", JSON.stringify(user?.user));
            localStorage.setItem("token", user?.token);
            setErrors({});
            handleClose();
            window.location.reload();
        }
    }, [user, navigate]);

    const validateForm = () => {
        let tempErrors = {};
        tempErrors.username = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(username)
            ? ""
            : "Enter a valid email address";
        tempErrors.password =
            /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{10,})/.test(password)
                ? ""
                : "Password: 10+ chars, 1 capital, 1 number, 1 symbol";
        setErrors(tempErrors);
        return Object.values(tempErrors).every((x) => x === "");
    };

    const handleLoginSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            const value = { email: username, password: password };
            try {
                await dispatch(login(value)).unwrap();
                localStorage.setItem("isLogin", true);
            } catch (err) {
                toast.error(err.message || "Login failed. Please try again.");
            }
        } else {
            // console.log("Form is invalid");
        }
    };
    return (
        <>
            <Dialog
                fullWidth={true}
                maxWidth="sm"
                sx={{
                    "& .MuiPaper-root": {
                        borderRadius: "12px", // Set your desired border radius here
                    },
                }}
                open={open}
                onClose={() => {
                    setErrors({});
                    handleClose();
                }}
            >
                <DialogTitle
                    style={{
                        padding: "0px",
                        paddingInline: "8px",
                        textAlign: "end",
                    }}
                >
                    <img
                        src={close}
                        className="dialogClose"
                        onClick={() => {
                            setErrors({});
                            handleClose();
                        }}
                    />
                </DialogTitle>
                <DialogContent style={{ padding: "0px" }}>
                    <div style={{ padding: "10px" }}>
                        <div
                            style={{
                                display: "flex",
                                height: "100%",
                                marginBottom: "2rem",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <img
                                className="loginPageMainLogo"
                                src={logo}
                            />
                            <Typography
                                sx={{
                                    fontWeight: "500",
                                    fontSize: "26px",
                                    letterSpacing: "1px",
                                }}
                                color="primary"
                            >
                                WELCOME BACK!
                            </Typography>
                            <Typography
                                sx={{
                                    fontWeight: "400",
                                    fontSize: "22px",
                                    color: "#6D6E71",
                                    letterSpacing: "1px",
                                }}
                            >
                                Please Login
                            </Typography>

                            <form
                                onSubmit={handleLoginSubmit}
                                style={{
                                    display: "flex",
                                    alignItems: 'center',
                                    flexDirection: "column",
                                }}
                            >
                                <TextField
                                    value={username}
                                    placeholder="Username"
                                    autoComplete="off"
                                    sx={{ marginTop: "2rem" }}
                                    error={!!errors.username}
                                    className="custom-text-field custom-text-field-modal"
                                    onChange={(e) => {
                                        setErrors((prev) => ({
                                            ...prev,
                                            username: "",
                                        }));
                                        setUsername(e.target.value);
                                    }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <MdOutlineEmail
                                                    size={20}
                                                    style={{
                                                        marginLeft: "4px",
                                                        marginRight: "6px",
                                                    }}
                                                />
                                                <VerticalDivider />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <p className="errormessage">
                                    {errors.username}
                                </p>

                                <TextField
                                    className="custom-text-field custom-text-field-modal"
                                    placeholder="Password"
                                    value={password}
                                    type="password"
                                    error={!!errors.password}
                                    onChange={(e) => {
                                        setErrors((prev) => ({
                                            ...prev,
                                            password: "",
                                        }));
                                        setPassword(e.target.value);
                                    }}
                                    sx={{ marginTop: "1rem" }}
                                    autoComplete="off"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <IoIosLock
                                                    size={20}
                                                    style={{
                                                        fontSize: "1.2rem",
                                                        marginRight: "5px",
                                                        marginLeft: '4px'
                                                    }}
                                                />
                                                <VerticalDivider />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <p className="errormessage">
                                    {errors.password}
                                </p>

                                <Button
                                    className="loginBtn custom-text-field-modal"
                                    type="submit"
                                    disabled={isLoading}
                                >
                                    {isLoading ? "Logging in..." : "LOGIN"}
                                </Button>
                            </form>

                            <div className="bottomCreateAccParent"
                                // style={{
                                //     display: "flex",
                                //     alignItems: "center",
                                //     marginTop: "1rem",
                                //     justifyContent: "space-between",
                                //     width: "26rem",
                                // }}
                            >
                                {/* <div>
                                    <Checkbox
                                        id="rememberme"
                                        sx={{ paddingInline: "0px" }}
                                        size="small"
                                        icon={<FaRegCircle />}
                                        checkedIcon={<FaCircle />}
                                    />
                                    <label
                                        htmlFor="rememberme"
                                        className="loginbottomoptions"
                                    >
                                        &nbsp;Remember me
                                    </label>
                                </div> */}

                                <div>
                                    <span
                                        onClick={() => navigate("/register")}
                                        className="loginbottomoptions"
                                    >
                                        Create an account?{" "}
                                        <span className="loginbottomcoloredoptions">
                                            Register
                                        </span>
                                    </span>
                                </div>
                                <div>
                                    <span
                                        onClick={() =>
                                            navigate("/forgot-password")
                                        }
                                        className="loginbottomoptions"
                                    >
                                        Forgot{" "}
                                        <span className="loginbottomcoloredoptions">
                                            Username
                                        </span>{" "}
                                        /{" "}
                                        <span className="loginbottomcoloredoptions">
                                            Password
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    );
}
