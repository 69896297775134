import React, { useEffect, useState } from "react";
import logo from "../Assets/Images/logo.png";
import { Button, Checkbox, Typography } from "@mui/material";
import { TextField, InputAdornment } from "@mui/material";
import { styled } from "@mui/system";
import { IoSearch } from "react-icons/io5"; // Or any other icon you prefer
import { IoIosLock } from "react-icons/io";
import { FaUser } from "react-icons/fa";
import element from "../Assets/Images/element.png";
import { MdEmail } from "react-icons/md";
import { FaRegCircle } from "react-icons/fa";
import { FaMobileAlt } from "react-icons/fa";
import { FaCircle } from "react-icons/fa";
import "./home.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { forgotPassword, resetForgotPasswordState } from "../Store/authSlice";
import { toast } from "react-toastify";

const VerticalDivider = styled("div")(({ theme }) => ({
    width: 3,
    height: 20,
    margin: theme.spacing(0.5, 0.5),
    backgroundColor: theme.palette.divider,
}));

export default function ForgotPassword() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [email, setEmail] = useState("");
    const { isLoading, error, forgotPasswordSuccess } = useSelector(
        (state) => state.auth
    );
    const [errors, setErrors] = useState({});

    useEffect(() => {
        // Reset the forgotPasswordSuccess state when component unmounts
        return () => {
            dispatch(resetForgotPasswordState());
        };
    }, [dispatch]);

    useEffect(() => {
        if (forgotPasswordSuccess) {
            navigate("/verification", { state: { email } });
        }
    }, [forgotPasswordSuccess, navigate]);

    const validateForm = () => {
        let tempErrors = {};
        tempErrors.email = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
            ? ""
            : "Enter a valid email address";
        setErrors(tempErrors);
        return Object.values(tempErrors).every((x) => x === "");
    };

    const handleSubmitBtn = async () => {
        if (validateForm()) {
            try {
                const value = { email, type: "reset-password" };
                await dispatch(forgotPassword(value)).unwrap();
            } catch (err) {
                toast.error(
                    err.message ||
                        "Failed to send reset password link. Please try again."
                );
            }
        } else {
            // console.log("Form is invalid");
        }
    };

    return (
        <>
            <div>
                <div
                    style={{
                        display: "flex",
                        position: "absolute",
                        top: "50%",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                    }}
                >
                    <img className="loginelementimage" src={element} />
                    <img
                        className="loginelementimage"
                        style={{ transform: "rotate(180deg)" }}
                        src={element}
                    />
                </div>
            </div>
            <div
                style={{
                    display: "flex",
                    height: "100vh",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <img
                    style={{ objectFit: "contain", width: "350px" }}
                    src={logo}
                />
                <Typography color="primary" className="registerwelcome">
                    Forgot Password
                </Typography>
                <Typography
                    className="registertext"
                    style={{ lineHeight: "20px" }}
                >
                    Please provide your email to have <br />a password reset
                    link sent to you.
                </Typography>

                <div>
                    <TextField
                        placeholder="Email"
                        className="custom-text-field"
                        autoComplete="off"
                        value={email}
                        error={!!errors.email}
                        onChange={(e) => {
                            setErrors("");
                            setEmail(e.target.value);
                        }}
                        sx={{ marginTop: "2rem" }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <MdEmail
                                        style={{
                                            fontSize: "1.2rem",
                                            marginRight: "4px",
                                        }}
                                    />
                                    <VerticalDivider />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <p className="errormessage">{errors.email}</p>
                </div>

                <Button
                    className="forgotPasswordSubmitButton"
                    variant="outlined"
                    sx={{marginTop: '2rem'}}
                    onClick={handleSubmitBtn}
                    disabled={isLoading}
                    >
                    {isLoading ? "SENDING..." : "SUBMIT"}
                </Button>
                <Button
                    color="secondary"
                    className="forgotPasswordSubmitButton"
                    sx={{marginTop: '1rem'}}
                    variant="outlined"
                    onClick={() => navigate("/login")}
                    disabled={isLoading}
                >
                    CANCEL
                </Button>
            </div>
        </>
    );
}
