import React from 'react';
import { useNavigate } from 'react-router-dom';
// import { XCircle } from 'lucide-react';
import { MdCancel } from 'react-icons/md';
import './home.css';
import { useSelector } from 'react-redux';

const StripeCancelPage = () => {
  const navigate = useNavigate();
  const cartItems = useSelector((state) => state?.cartReducer?.items || []);

  const totalPrice = cartItems?.items?.reduce((total, item) => {
    // Convert price to a number and calculate the total
    const price = parseFloat(item.price);
    return total + (price * item.quantity);
}, 0); // Initial value of total is 0

  return (
    <div className="cancel-page">
      <div className="cancel-card">
        <MdCancel className="cancel-icon" />
        <h1 className="cancel-title">Payment Cancelled</h1>
        <p className="cancel-message">
          Your payment process has been cancelled. No charges were made to your account.
        </p>
        <div className="button-group">
          {/* <button 
            onClick={() => navigate('/order-summary', {state: totalPrice?.toFixed(2)})} 
            className="retry-button"
          >
            Try Again
          </button> */}
          <button 
            onClick={() => navigate('/')} 
            className="home-button"
          >
            Return to Home
          </button>
        </div>
      </div>
    </div>
  );
};

export default StripeCancelPage;
