import React, { useEffect, useState } from "react";
import HomeNav from "../Component/HomeNav";
import {
    Button,
    Checkbox,
    Collapse,
    Container,
    Divider,
    Grid,
    MenuItem,
    Select,
    Step,
    StepLabel,
    Stepper,
    styled,
    Typography,
} from "@mui/material";
import { IoChevronBackOutline, IoMail } from "react-icons/io5";
import CustomizedSteppers from "../Component/CustomizedStepper";
import CartHeading from "../Component/CartHeading";
import { CgLoadbarDoc } from "react-icons/cg";
import { HiDocumentText } from "react-icons/hi2";
import { GrLinkNext, GrLinkPrevious } from "react-icons/gr";
import { MdOutlinePayments } from "react-icons/md";
import { BiMenuAltLeft, BiSolidEdit, BiTrash } from "react-icons/bi";
import "./home.css";
import axios from "axios";
import { baseUrl } from "../Constant_key";
import { toast } from "react-toastify";
import { resolvePath, useLocation, useNavigate } from "react-router-dom";
import ShippingOptions from "../Component/ShippingOptions";
import { useSelector } from "react-redux";
import { loadStripe } from "@stripe/stripe-js";

import { useElements, useStripe } from "@stripe/react-stripe-js";
import BillingInformation from "../Component/BillingInformation";
import ShippingInformation from "../Component/ShippingInformation";

export default function OrderSummary() {
    const navigate = useNavigate();
    const location = useLocation();

    const [searchModalopen, setSearchModalOpen] = useState(false);
    const [orderStatus, setOrderStatus] = useState(0);
    const [paymentMethod, setPaymentMethod] = useState(
        "Credit Card/Debit Card"
    );
    const [addressId, setAddressId] = useState(null);
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedAddress, setSelectedAddress] = useState(null);
    const [selectedShippingAddress, setSelectedShippingAddress] =
        useState(null);

    const [isShippingAddressOpen, setIsShippingAddressOpen] = useState(false);
    const [isBillingAddressOpen, setIsBillingAddressOpen] = useState(false);

    const [error, setError] = useState(null);
    const [merchandise, setMerchandise] = useState(location?.state);
    const [copyShippingDetails, setCopyShippingDetails] = useState(1);
    const [allBillingAddress, setAllBillingAddress] = useState([]);
    const [allShippingAddress, setAllShippingAddress] = useState([]);
    const [age, setAge] = React.useState("");

    const CustomSelect = styled(Select)(({ theme }) => ({
        "& .MuiSelect-select": {
            padding: "6px 10px",
        },
    }));

    const initialBillingInfo = {
        id: 0,
        first_name: "",
        last_name: "",
        phone_number: "",
        companyName: "",
        address_line1: "",
        address_line2: "",
        city: "",
        state: "",
        country: "",
        zip_code: "",
    };
    const [billingInfo, setBillingInfo] = useState(initialBillingInfo);
    const [shippingInfo, setShippingInfo] = useState(initialBillingInfo);

    const initialErrorValue = {
        first_name: "",
        last_name: "",
        phone_number: "",
        companyName: "",
        address_line1: "",
        city: "",
        state: "",
        country: "",
        zip_code: "",
    };
    const [errors, setErrors] = useState(initialErrorValue);
    const [shippingErrors, setShippingErrors] = useState({
        first_name: "",
        last_name: "",
        phone_number: "",
        companyName: "",
        address_line1: "",
        city: "",
        state: "",
        country: "",
        zip_code: "",
    });
    const [pdfBase64, setPdfBase64] = useState("");
    const [salesTaxStatus, setSalesTaxStatus] = useState(null);
    const [isTaxable, setIsTaxable] = useState(true);
    const [checkoutId, setCheckoutId] = useState(true);
    const [salesTax, setSalesTax] = useState(0);
    const [clientSecret, setClientSecret] = useState("");
    const [stripePromise, setStripePromise] = useState(null);
    const [billingInfoStates, setBillingInfoStates] = useState(null);
    const [billingInfoCity, setBillingInfoCity] = useState(null);
    const [ShippingInfoStates, setShippingInfoStates] = useState(null);
    const [ShippingInfoCity, setShippingInfoCity] = useState(null);
    const cartItems = useSelector((state) => state?.cartReducer?.items || []);
    const elements = useElements();
    const stripe = useStripe();

    useEffect(() => {
        if (orderStatus == 0) {
            getAllAddress();
        }
    }, [orderStatus]);

    useEffect(() => {
        if (billingInfo.country) {
            axios
                .get(`${baseUrl}api/states/${billingInfo.country}`)
                .then((res) => {
                    if (res.data?.length > 0) {
                        setBillingInfoStates(res.data);
                    }
                })
                .catch((err) => {
                    // console.log(err)
                });
        }
    }, [billingInfo.country]);

    useEffect(() => {
        if (billingInfo.state) {
            axios
                .get(`${baseUrl}api/cities/${billingInfo.state}`)
                .then((res) => {
                    if (res.data?.length > 0) {
                        setBillingInfoCity(res.data);
                    }
                })
                .catch((err) => {
                    // console.log(err)
                });
        }
    }, [billingInfo.state]);

    useEffect(() => {
        if (shippingInfo.country) {
            axios
                .get(`${baseUrl}api/states/${shippingInfo.country}`)
                .then((res) => {
                    if (res.data?.length > 0) {
                        setShippingInfoStates(res.data);
                    }
                })
                .catch((err) => {
                    // console.log(err)
                });
        }
    }, [shippingInfo.country]);

    useEffect(() => {
        if (shippingInfo.state) {
            axios
                .get(`${baseUrl}api/cities/${shippingInfo.state}`)
                .then((res) => {
                    if (res.data?.length > 0) {
                        setShippingInfoCity(res.data);
                    }
                })
                .catch((err) => {
                    // console.log(err)
                });
        }
    }, [shippingInfo.state]);

    const getAllAddress = () => {
        const token = localStorage.getItem("token");

        axios
            .get(`${baseUrl}api/address/get`, {
                headers: { Authorization: `bearer ${token}` },
            })
            .then((res) => {
                if (res.data?.status == 200) {
                    setAllBillingAddress(res.data?.billing_addresses);
                    setAllShippingAddress(res.data?.shipping_addresses);
                    // setBillingInfo({ ...billingadd });
                }
            })
            .catch((err) => {
                // console.log(err)
            });
    };

    const handleChange = (event) => {
        setAge(event.target.value);
    };

    const handleSearchClickOpen = () => {
        setSearchModalOpen(true);
    };

    const handleBackToPrevBtn = () => {
        if (orderStatus != 0) {
            setOrderStatus((prev) => Math.min(4, Math.max(0, prev - 1)));
        }
    };

    const handleBillingChange = (e) => {
        const { name, value } = e.target;

        setBillingInfo((prev) => ({ ...prev, [name]: value })); // Use [name] to dynamically update the correct property
    };

    const handleShippingChange = (e) => {
        const { name, value } = e.target;

        setShippingInfo((prev) => ({ ...prev, [name]: value })); // Use [name] to dynamically update the correct property
    };

    // const handleContinueBtn = () => {
    //     if (validateForm()) {
    //         if (copyShippingDetails == 0 ? validateShippingForm() : true) {
    //             const billing_addresses = [
    //                 {
    //                     first_name: billingInfo.first_name,
    //                     last_name: billingInfo.last_name,
    //                     phone_number: billingInfo.phone_number,
    //                     address_line1: billingInfo.address_line1,
    //                     address_line2: billingInfo.address_line2,
    //                     city: billingInfo.city,
    //                     state: billingInfo.state,
    //                     country: billingInfo.country,
    //                     zip_code: billingInfo.zip_code,
    //                 },
    //             ];

    //             const shipping_addresses = [
    //                 {
    //                     first_name: shippingInfo?.first_name,
    //                     last_name: shippingInfo?.last_name,
    //                     phone_number: shippingInfo?.phone_number,
    //                     address_line1: shippingInfo?.address_line1,
    //                     address_line2: shippingInfo?.address_line2,
    //                     city: shippingInfo?.city,
    //                     state: shippingInfo?.state,
    //                     country: shippingInfo?.country,
    //                     zip_code: shippingInfo?.zip_code,
    //                 },
    //             ];

    //             const token = localStorage.getItem("token");

    //             if (selectedAddress?.id && copyShippingDetails == 1) {
    //                 setAddressId(billingInfo?.id);
    //                 setOrderStatus((prev) =>
    //                     Math.min(4, Math.max(0, prev + 1))
    //                 );
    //             } else if (
    //                 selectedAddress == null &&
    //                 copyShippingDetails == 1
    //             ) {
    //                 const value = {
    //                     billing_addresses: billing_addresses,
    //                     shipping_addresses: billing_addresses,
    //                 };

    //                 axios
    //                     .post(`${baseUrl}api/address/add`, value, {
    //                         headers: { Authorization: `Bearer ${token}` },
    //                     })
    //                     .then((res) => {
    //                         if (res.data.status == 200) {
    //                             setAddressId(
    //                                 res?.data?.inserted_addresses?.shipping[0]
    //                                     ?.id
    //                             );
    //                             setBillingInfo(initialBillingInfo);
    //                             setShippingInfo(initialBillingInfo);
    //                             setOrderStatus((prev) =>
    //                                 Math.min(4, Math.max(0, prev + 1))
    //                             );
    //                             toast.success(res.data.message);
    //                         }
    //                     })
    //                     .catch((err) => {
    //                         // console.log(err)
    //                     });
    //             } else if (
    //                 selectedShippingAddress?.id &&
    //                 selectedAddress == null &&
    //                 copyShippingDetails == 0
    //             ) {
    //                 const value = {
    //                     billing_addresses: billing_addresses,
    //                     shipping_addresses: [],
    //                 };

    //                 axios
    //                     .post(`${baseUrl}api/address/add`, value, {
    //                         headers: { Authorization: `Bearer ${token}` },
    //                     })
    //                     .then((res) => {
    //                         if (res.data.status == 200) {
    //                             setAddressId(shippingInfo?.id);
    //                             setOrderStatus((prev) =>
    //                                 Math.min(4, Math.max(0, prev + 1))
    //                             );
    //                             toast.success(res.data.message);
    //                         }
    //                     })
    //                     .catch((err) => {
    //                         // console.log(err)
    //                     });
    //             } else if (
    //                 selectedAddress?.id &&
    //                 selectedShippingAddress == null &&
    //                 copyShippingDetails == 0
    //             ) {
    //                 const value = {
    //                     billing_addresses: [],
    //                     shipping_addresses: shipping_addresses,
    //                 };
    //                 axios
    //                     .post(`${baseUrl}api/address/add`, value, {
    //                         headers: { Authorization: `Bearer ${token}` },
    //                     })
    //                     .then((res) => {
    //                         if (res.data.status == 200) {
    //                             setAddressId(
    //                                 res?.data?.inserted_addresses?.shipping[0]
    //                                     ?.id
    //                             );
    //                             setOrderStatus((prev) =>
    //                                 Math.min(4, Math.max(0, prev + 1))
    //                             );
    //                             toast.success(res.data.message);
    //                         }
    //                     })
    //                     .catch((err) => {
    //                         // console.log(err)
    //                     });
    //             } else if (
    //                 selectedAddress == null &&
    //                 selectedShippingAddress == null &&
    //                 copyShippingDetails == 0
    //             ) {
    //                 const value = {
    //                     billing_addresses: billing_addresses,
    //                     shipping_addresses: shipping_addresses,
    //                 };

    //                 axios
    //                     .post(`${baseUrl}api/address/add`, value, {
    //                         headers: { Authorization: `Bearer ${token}` },
    //                     })
    //                     .then((res) => {
    //                         if (res.data.status == 200) {
    //                             setAddressId(
    //                                 res?.data?.inserted_addresses?.shipping[0]
    //                                     ?.id
    //                             );
    //                             setOrderStatus((prev) =>
    //                                 Math.min(4, Math.max(0, prev + 1))
    //                             );
    //                             toast.success(res.data.message);
    //                         }
    //                     })
    //                     .catch((err) => {
    //                         // console.log(err)
    //                     });
    //             } else if (
    //                 selectedShippingAddress?.id &&
    //                 selectedAddress?.id &&
    //                 copyShippingDetails == 0
    //             ) {
    //                 setAddressId(shippingInfo?.id);
    //                 setOrderStatus((prev) =>
    //                     Math.min(4, Math.max(0, prev + 1))
    //                 );
    //             }

    //             return false;
    //         }
    //     } else {
    //         toast.error("Please fill all required fields correctly.");
    //     }
    // };

    const handleContinueBtn = () => {
        if (validateShippingForm()) {
            if (copyShippingDetails == 0 ? validateForm() : true) {
                const billing_addresses = [
                    {
                        first_name: billingInfo.first_name,
                        last_name: billingInfo.last_name,
                        phone_number: billingInfo.phone_number,
                        address_line1: billingInfo.address_line1,
                        address_line2: billingInfo.address_line2,
                        city: billingInfo.city,
                        state: billingInfo.state,
                        country: billingInfo.country,
                        zip_code: billingInfo.zip_code,
                    },
                ];

                const shipping_addresses = [
                    {
                        first_name: shippingInfo?.first_name,
                        last_name: shippingInfo?.last_name,
                        phone_number: shippingInfo?.phone_number,
                        address_line1: shippingInfo?.address_line1,
                        address_line2: shippingInfo?.address_line2,
                        city: shippingInfo?.city,
                        state: shippingInfo?.state,
                        country: shippingInfo?.country,
                        zip_code: shippingInfo?.zip_code,
                    },
                ];

                const token = localStorage.getItem("token");

                if (selectedShippingAddress?.id && copyShippingDetails == 1) {
                    setAddressId(shippingInfo?.id);
                    setOrderStatus((prev) =>
                        Math.min(4, Math.max(0, prev + 1))
                    );
                } else if (
                    selectedShippingAddress == null &&
                    copyShippingDetails == 1
                ) {
                    const value = {
                        billing_addresses: shipping_addresses,
                        shipping_addresses: shipping_addresses,
                    };

                    axios
                        .post(`${baseUrl}api/address/add`, value, {
                            headers: { Authorization: `Bearer ${token}` },
                        })
                        .then((res) => {
                            if (res.data.status == 200) {
                                setAddressId(
                                    res?.data?.inserted_addresses?.shipping[0]
                                        ?.id
                                );
                                setBillingInfo(initialBillingInfo);
                                setShippingInfo(initialBillingInfo);
                                setOrderStatus((prev) =>
                                    Math.min(4, Math.max(0, prev + 1))
                                );
                                toast.success(res.data.message);
                            }
                        })
                        .catch((err) => {
                            // console.log(err)
                        });
                } else if (
                    selectedAddress?.id &&
                    selectedShippingAddress == null &&
                    copyShippingDetails == 0
                ) {
                    const value = {
                        billing_addresses: [],
                        shipping_addresses: shipping_addresses,
                    };

                    axios
                        .post(`${baseUrl}api/address/add`, value, {
                            headers: { Authorization: `Bearer ${token}` },
                        })
                        .then((res) => {
                            if (res.data.status == 200) {
                                setAddressId(
                                    res?.data?.inserted_addresses?.shipping[0]
                                        ?.id
                                );
                                setOrderStatus((prev) =>
                                    Math.min(4, Math.max(0, prev + 1))
                                );
                                toast.success(res.data.message);
                            }
                        })
                        .catch((err) => {
                            // console.log(err)
                        });
                } else if (
                    selectedShippingAddress?.id &&
                    selectedAddress == null &&
                    copyShippingDetails == 0
                ) {
                    const value = {
                        billing_addresses: billing_addresses,
                        shipping_addresses: [],
                    };
                    axios
                        .post(`${baseUrl}api/address/add`, value, {
                            headers: { Authorization: `Bearer ${token}` },
                        })
                        .then((res) => {
                            if (res.data.status == 200) {
                                setAddressId(shippingInfo?.id);
                                setOrderStatus((prev) =>
                                    Math.min(4, Math.max(0, prev + 1))
                                );
                                toast.success(res.data.message);
                            }
                        })
                        .catch((err) => {
                            // console.log(err)
                        });
                } else if (
                    selectedShippingAddress == null &&
                    selectedAddress == null &&
                    copyShippingDetails == 0
                ) {
                    const value = {
                        billing_addresses: billing_addresses,
                        shipping_addresses: shipping_addresses,
                    };

                    axios
                        .post(`${baseUrl}api/address/add`, value, {
                            headers: { Authorization: `Bearer ${token}` },
                        })
                        .then((res) => {
                            if (res.data.status == 200) {
                                setAddressId(
                                    res?.data?.inserted_addresses?.shipping[0]
                                        ?.id
                                );
                                setOrderStatus((prev) =>
                                    Math.min(4, Math.max(0, prev + 1))
                                );
                                toast.success(res.data.message);
                            }
                        })
                        .catch((err) => {
                            // console.log(err)
                        });
                } else if (
                    selectedAddress?.id &&
                    selectedShippingAddress?.id &&
                    copyShippingDetails == 0
                ) {
                    setAddressId(shippingInfo?.id);
                    setOrderStatus((prev) =>
                        Math.min(4, Math.max(0, prev + 1))
                    );
                }

                return false;
            } else {
                toast.error("Please select billing information or fill all required fields correctly.");
            }
        } else {
            toast.error("Please select shipping information or Please fill all required fields correctly.");
        }
    };

    const validateForm = () => {
        let isValid = true;
        let newErrors = {};

        // First Name
        if (!billingInfo.first_name.trim()) {
            newErrors.first_name = "First Name is required";
            isValid = false;
        }

        // Last Name
        if (!billingInfo.last_name.trim()) {
            newErrors.last_name = "Last Name is required";
            isValid = false;
        }

        // Phone
        const phoneRegex = /^\d{10}$/;
        if (
            !billingInfo.phone_number ||
            !phoneRegex.test(billingInfo.phone_number)
        ) {
            newErrors.phone_number = "Valid 10-digit Phone number is required";
            isValid = false;
        }

        // Address Line 1
        if (!billingInfo.address_line1?.trim()) {
            newErrors.address_line1 = "Address is required";
            isValid = false;
        }

        // City
        if (!billingInfo.city?.trim()) {
            newErrors.city = "City is required";
            isValid = false;
        }

        // State
        if (!billingInfo.state) {
            newErrors.state = "State is required";
            isValid = false;
        }

        // Country
        if (!billingInfo.country) {
            newErrors.country = "Country is required";
            isValid = false;
        }

        // Zip Code
        const zipRegex = /^\d{5}(-\d{4})?$/;
        if (!billingInfo.zip_code || !zipRegex.test(billingInfo.zip_code)) {
            newErrors.zip_code = "Valid Zip Code is required";
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const validateShippingForm = () => {
        let isValid = true;
        let newErrors = {};

        // First Name
        if (!shippingInfo.first_name.trim()) {
            newErrors.first_name = "First Name is required";
            isValid = false;
        }

        // Last Name
        if (!shippingInfo.last_name.trim()) {
            newErrors.last_name = "Last Name is required";
            isValid = false;
        }

        // Phone
        const phoneRegex = /^\d{10}$/;
        if (
            !shippingInfo.phone_number ||
            !phoneRegex.test(shippingInfo.phone_number)
        ) {
            newErrors.phone_number = "Valid 10-digit Phone number is required";
            isValid = false;
        }

        // Address Line 1
        if (!shippingInfo.address_line1?.trim()) {
            newErrors.address_line1 = "Address is required";
            isValid = false;
        }

        // City
        if (!shippingInfo.city?.trim()) {
            newErrors.city = "City is required";
            isValid = false;
        }

        // State
        if (!shippingInfo.state) {
            newErrors.state = "State is required";
            isValid = false;
        }

        // Country
        if (!shippingInfo.country) {
            newErrors.country = "Country is required";
            isValid = false;
        }

        // Zip Code
        const zipRegex = /^\d{5}(-\d{4})?$/;
        if (!shippingInfo.zip_code || !zipRegex.test(shippingInfo.zip_code)) {
            newErrors.zip_code = "Valid Zip Code is required";
            isValid = false;
        }

        setShippingErrors(newErrors);
        return isValid;
    };

    const handleBackToCart = () => {
        navigate("/shopping-cart");
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];

        if (file && file.type === "application/pdf") {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                const base64String = reader.result.split(",")[1]; // Get base64 string after comma
                setPdfBase64(base64String);
                setError(null);
            };
        } else {
            alert("Please upload a valid PDF file.");
        }
    };

    const handleShippingContinueBtn = async () => {
        if (selectedOption == null) {
            setError("Please Select Shipping Method");
            toast.error("Please Select Shipping Method");
            return false;
        }

        if (salesTaxStatus == "FALSE" || salesTaxStatus == null) {
            if (isTaxable == false) {
                if (pdfBase64 == "") {
                    setError("Please Upload Resale or Exemption Certificate");
                    toast.error(
                        "Please Upload Resale or Exemption Certificate"
                    );
                    return false;
                }
            }
        }

        const value = {
            shippingServiceCode: selectedOption?.serviceCode,
            shippingCharges: selectedOption?.totalCharge,
            salesCertification: isTaxable ? "" : pdfBase64,
            subTotal: merchandise,
            cartId: cartItems?.cart?.cart_id,
            salesTaxStatus: salesTaxStatus == "FALSE" ? false : salesTaxStatus == "TRUE" ? true : salesTaxStatus,
        };
        const token = await localStorage.getItem("token");

        axios
            .post(`${baseUrl}api/process-shipping`, value, {
                headers: { Authorization: `bearer ${token}` },
            })
            .then((res) => {
                if (res.data?.checkoutId) {
                    setCheckoutId(res.data?.checkoutId);
                    setSalesTax(res.data?.taxValue);
                    setOrderStatus((prev) =>
                        Math.min(4, Math.max(0, prev + 1))
                    );
                }
            })
            .catch((err) => {
                if (err?.response?.status == 403) {
                    // console.log("err?.response?.data?.error?.message", err?.response?.data);
                    navigate("/login");
                    toast.error(
                        "Your session has expired. Please log in again to continue."
                    );
                }
            });
    };

    const makePayment = async () => {
        const publishKey = await loadStripe(process.env.REACT_APP_PUBLISH_KEY);

        const value = {
            checkoutId: checkoutId,
            cartId: cartItems?.cart?.cart_id,
            amount: 1000.0,
            paymentMethod: "card",
        };
        const token = localStorage.getItem("token");

        axios
            .post(`${baseUrl}api/create-checkout-session`, value, {
                headers: { Authorization: `bearer ${token}` },
            })
            .then((res) => {
                if (res.data?.sessionId) {
                    // setClientSecret(res.data?.clientSecret);
                    const result = publishKey.redirectToCheckout({
                        sessionId: res.data?.sessionId,
                    });

                    if (result.error) {
                        // console.log("errrr", result);
                    }
                }
            })
            .catch((err) => {
                // console.log(err)
            });
    };

    const handleDeleteAddress = (id) => {
        const token = localStorage.getItem("token");
        const value = {
            address_ids: [id],
        };
        axios
            .post(`${baseUrl}api/address/delete`, value, {
                headers: { Authorization: `bearer ${token}` },
            })
            .then((res) => {
                if (res.data.status == 200) {
                    toast.success(res.data?.message);
                    getAllAddress();
                }
            })
            .catch((err) => {
                // console.log(err)
            });
    };

    return (
        <div>
            <HomeNav handleSearchClickOpen={handleSearchClickOpen} />
            <div style={{ display: "flex", justifyContent: "center" }}>
                <div className="mainnavbar">
                    {/* <Container sx={{ marginBottom: "2rem" }}> */}
                    <Grid sx={{ marginTop: "10px" }} container spacing={2}>
                        <Grid item xs={12} sm={6} md={7}>
                            <div
                                style={{
                                    marginTop: "1rem",
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <IoChevronBackOutline
                                    onClick={handleBackToCart}
                                    style={{ cursor: "pointer" }}
                                    fontSize={18}
                                />
                                <Typography
                                    onClick={handleBackToCart}
                                    className="backtocartbtn"
                                >
                                    Back to Cart
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={6} md={5}>
                            <CustomizedSteppers status={orderStatus} />
                        </Grid>
                    </Grid>

                    <Divider sx={{ marginTop: "10px" }} />

                    <Grid sx={{ marginTop: "10px" }} container spacing={2}>
                        <Grid item xs={12} md={7}>
                            {orderStatus == 0 && (
                                <div>
                                    <>
                                        <CartHeading
                                            icon={<IoMail fontSize={14} />}
                                            heading={"Addresses"}
                                            caption={
                                                "Shipping Information (* Required Field)"
                                            }
                                        />
                                        <div style={{ marginTop: "1.5rem" }}>
                                            {allShippingAddress?.map((item) => (
                                                <div
                                                    key={item.id}
                                                    className={`option ${
                                                        selectedShippingAddress?.id ===
                                                        item.id
                                                            ? "selected"
                                                            : ""
                                                    }`}
                                                    onClick={() => {
                                                        if (
                                                            selectedShippingAddress?.id ===
                                                            item.id
                                                        ) {
                                                            setSelectedShippingAddress(
                                                                null
                                                            );
                                                            setShippingInfo(
                                                                initialBillingInfo
                                                            );
                                                        } else {
                                                            setSelectedShippingAddress(
                                                                item
                                                            );
                                                            setShippingErrors(
                                                                initialErrorValue
                                                            );
                                                            setShippingInfo(
                                                                item
                                                            );
                                                        }
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            minWidth: "50px",
                                                        }}
                                                    >
                                                        <Checkbox
                                                            color="primary"
                                                            size="small"
                                                            onChange={() => {}}
                                                            checked={
                                                                selectedShippingAddress?.id ===
                                                                item.id
                                                            }
                                                        />
                                                    </div>
                                                    <div className="option-details">
                                                        <div
                                                            style={{
                                                                minWidth:
                                                                    "100px",
                                                                flex: 1,
                                                            }}
                                                        >
                                                            <div className="option-name">
                                                                {`${item?.address_line1}, ${item?.city}, ${item?.state}, (${item?.zip_code})`}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleDeleteAddress(
                                                                item?.id
                                                            );
                                                        }}
                                                        style={{
                                                            minWidth: "20px",
                                                            cursor: "pointer",
                                                        }}
                                                    >
                                                        <BiTrash />
                                                    </div>
                                                </div>
                                            ))}
                                        </div>

                                        {/* {allShippingAddress?.length > 0 && ( */}
                                        <Button
                                            onClick={() => {
                                                setSelectedShippingAddress(
                                                    null
                                                );
                                                setShippingInfo(
                                                    initialBillingInfo
                                                );
                                                setIsShippingAddressOpen(
                                                    (prev) => !prev
                                                );
                                            }}
                                            variant="outlined"
                                        >
                                            Add New Address
                                        </Button>
                                        {/* )} */}

                                        <Collapse in={isShippingAddressOpen}>
                                            <div className="shipping-address-container">
                                                <ShippingInformation
                                                    shippingInfo={shippingInfo}
                                                    shippingErrors={
                                                        shippingErrors
                                                    }
                                                    ShippingInfoStates={
                                                        ShippingInfoStates
                                                    }
                                                    ShippingInfoCity={
                                                        ShippingInfoCity
                                                    }
                                                    handleShippingChange={
                                                        handleShippingChange
                                                    }
                                                />
                                            </div>
                                        </Collapse>
                                    </>

                                    <hr style={{ marginTop: "1rem" }} />

                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <label style={{ fontSize: "11px" }}>
                                            Copy these details to my billing
                                            information? *
                                        </label>

                                        <div>
                                            <Checkbox
                                                color="primary"
                                                size="small"
                                                onChange={() => {
                                                    setShippingErrors({
                                                        first_name: "",
                                                        last_name: "",
                                                        phone_number: "",
                                                        companyName: "",
                                                        address_line1: "",
                                                        city: "",
                                                        state: "",
                                                        country: "",
                                                        zip_code: "",
                                                    });
                                                    setCopyShippingDetails(1);
                                                }}
                                                checked={Boolean(
                                                    copyShippingDetails
                                                )}
                                            />
                                            <label
                                                style={{
                                                    fontSize: "11px",
                                                    fontWeight: 500,
                                                }}
                                            >
                                                Yes
                                            </label>
                                        </div>
                                        <div>
                                            <Checkbox
                                                color="primary"
                                                size="small"
                                                onChange={() =>
                                                    setCopyShippingDetails(0)
                                                }
                                                checked={Boolean(
                                                    !copyShippingDetails
                                                )}
                                            />
                                            <label
                                                style={{
                                                    fontSize: "11px",
                                                    fontWeight: 500,
                                                }}
                                            >
                                                No
                                            </label>
                                        </div>
                                    </div>

                                    {copyShippingDetails == 0 && (
                                        <>
                                            <CartHeading
                                                icon={<IoMail fontSize={14} />}
                                                heading={"Addresses"}
                                                caption={
                                                    "Billing Information (* Required Field)"
                                                }
                                            />
                                            <div style={{ marginTop: "2rem" }}>
                                                {allBillingAddress?.map(
                                                    (item) => (
                                                        <div
                                                            key={item?.id}
                                                            className={`option ${
                                                                selectedAddress?.id ===
                                                                item.id
                                                                    ? "selected"
                                                                    : ""
                                                            }`}
                                                            onClick={() => {
                                                                if (
                                                                    selectedAddress?.id ===
                                                                    item.id
                                                                ) {
                                                                    setSelectedAddress(
                                                                        null
                                                                    );
                                                                    setBillingInfo(
                                                                        initialBillingInfo
                                                                    );
                                                                } else {
                                                                    setSelectedAddress(
                                                                        item
                                                                    );
                                                                    setErrors(
                                                                        initialErrorValue
                                                                    );
                                                                    setBillingInfo(
                                                                        item
                                                                    );
                                                                }
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    minWidth:
                                                                        "50px",
                                                                }}
                                                            >
                                                                <Checkbox
                                                                    color="primary"
                                                                    size="small"
                                                                    onChange={() => {}}
                                                                    checked={
                                                                        selectedAddress?.id ===
                                                                        item.id
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="option-details">
                                                                <div
                                                                    style={{
                                                                        minWidth:
                                                                            "100px",
                                                                        flex: 1,
                                                                    }}
                                                                >
                                                                    <div className="option-name">
                                                                        {`${item?.address_line1}, ${item?.city}, ${item?.state}, (${item?.zip_code})`}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    e.stopPropagation();
                                                                    handleDeleteAddress(
                                                                        item?.id
                                                                    );
                                                                }}
                                                                style={{
                                                                    minWidth:
                                                                        "20px",
                                                                    cursor: "pointer",
                                                                }}
                                                            >
                                                                <BiTrash />
                                                            </div>
                                                        </div>
                                                    )
                                                )}
                                            </div>

                                            {allBillingAddress?.length > 0 && (
                                                <Button
                                                    onClick={() => {
                                                        setSelectedAddress(
                                                            null
                                                        );
                                                        setBillingInfo(
                                                            initialBillingInfo
                                                        );
                                                        setIsBillingAddressOpen(
                                                            (prev) => !prev
                                                        );
                                                    }}
                                                    variant="outlined"
                                                >
                                                    Add New Address
                                                </Button>
                                            )}

                                            <Collapse in={isBillingAddressOpen}>
                                                <BillingInformation
                                                    billingInfo={billingInfo}
                                                    errors={errors}
                                                    billingInfoStates={
                                                        billingInfoStates
                                                    }
                                                    billingInfoCity={
                                                        billingInfoCity
                                                    }
                                                    handleBillingChange={
                                                        handleBillingChange
                                                    }
                                                />
                                            </Collapse>
                                        </>
                                    )}
                                </div>
                            )}

                            {orderStatus == 1 && (
                                <ShippingOptions
                                    setError={setError}
                                    handleFileChange={handleFileChange}
                                    pdfBase64={pdfBase64}
                                    salesTaxStatus={salesTaxStatus}
                                    setSalesTaxStatus={setSalesTaxStatus}
                                    isTaxable={isTaxable}
                                    setIsTaxable={setIsTaxable}
                                    setPdfBase64={setPdfBase64}
                                    addressId={addressId}
                                    setSelectedOption={setSelectedOption}
                                    selectedOption={selectedOption}
                                    error={error}
                                />
                            )}

                            {orderStatus == 2 && (
                                <div>
                                    <CartHeading
                                        icon={
                                            <MdOutlinePayments fontSize={14} />
                                        }
                                        heading={"Payment Method"}
                                        caption={"Please select applicable one"}
                                    />

                                    <div style={{ marginLeft: "2.3rem" }}>
                                        <div style={{ marginTop: "10px" }}>
                                            <Checkbox
                                                id="netterms"
                                                checked={
                                                    paymentMethod == "NET Terms"
                                                }
                                                onChange={() =>
                                                    setPaymentMethod(
                                                        "NET Terms"
                                                    )
                                                }
                                                sx={{ padding: "0px" }}
                                                size="small"
                                            />
                                            <label
                                                htmlFor="netterms"
                                                className="paymentMethodMenuOptions"
                                            >
                                                &nbsp;&nbsp;NET Terms
                                            </label>
                                        </div>
                                        <div style={{ marginTop: "10px" }}>
                                            <Checkbox
                                                id="creditdebitcard"
                                                checked={
                                                    paymentMethod ==
                                                    "Credit Card/Debit Card"
                                                }
                                                onChange={() =>
                                                    setPaymentMethod(
                                                        "Credit Card/Debit Card"
                                                    )
                                                }
                                                sx={{ padding: "0px" }}
                                                size="small"
                                            />
                                            <label
                                                htmlFor="creditdebitcard"
                                                className="paymentMethodMenuOptions"
                                            >
                                                &nbsp;&nbsp;Credit Card/Debit
                                                Card
                                            </label>
                                        </div>
                                        <div style={{ marginTop: "10px" }}>
                                            <Checkbox
                                                id="wiretransfer"
                                                checked={
                                                    paymentMethod ==
                                                    "Wire Transfer/Proforma"
                                                }
                                                onChange={() =>
                                                    setPaymentMethod(
                                                        "Wire Transfer/Proforma"
                                                    )
                                                }
                                                sx={{ padding: "0px" }}
                                                size="small"
                                            />
                                            <label
                                                htmlFor="wiretransfer"
                                                className="paymentMethodMenuOptions"
                                            >
                                                &nbsp;&nbsp;Wire
                                                Transfer/Proforma
                                            </label>
                                        </div>
                                        {/* <div style={{ marginTop: "10px" }}>
                                            <Checkbox
                                                id="cod"
                                                checked={paymentMethod == "COD"}
                                                onChange={() =>
                                                    setPaymentMethod("COD")
                                                }
                                                sx={{ padding: "0px" }}
                                                size="small"
                                            />
                                            <label
                                                htmlFor="cod"
                                                className="paymentMethodMenuOptions"
                                            >
                                                &nbsp;&nbsp;COD
                                            </label>
                                        </div> */}
                                    </div>

                                    <Divider sx={{ marginTop: "1rem" }} />

                                    <div>
                                        <div style={{ marginTop: "10px" }}>
                                            <label className="purchaseorderlabel">
                                                Purchase Order Number:
                                            </label>
                                            <div>
                                                <input
                                                    placeholder="Optional"
                                                    className="purchaseorderinput"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {orderStatus == 3 && (
                                <div>
                                    <CartHeading
                                        icon={
                                            <MdOutlinePayments fontSize={14} />
                                        }
                                        heading={"Payment Method"}
                                        caption={"Please select applicable one"}
                                    />

                                    <div style={{ marginLeft: "2.3rem" }}>
                                        <div style={{ marginTop: "10px" }}>
                                            <Checkbox
                                                id="netterms"
                                                checked={
                                                    paymentMethod == "NET Terms"
                                                }
                                                onChange={() =>
                                                    setPaymentMethod(
                                                        "NET Terms"
                                                    )
                                                }
                                                sx={{ padding: "0px" }}
                                                size="small"
                                            />
                                            <label
                                                htmlFor="netterms"
                                                style={{
                                                    fontSize: "11px",
                                                    fontWeight: 500,
                                                }}
                                            >
                                                &nbsp;&nbsp;NET Terms
                                            </label>
                                        </div>
                                        <div style={{ marginTop: "10px" }}>
                                            <Checkbox
                                                id="creditdebitcard"
                                                checked={
                                                    paymentMethod ==
                                                    "Credit Card/Debit Card"
                                                }
                                                onChange={() =>
                                                    setPaymentMethod(
                                                        "Credit Card/Debit Card"
                                                    )
                                                }
                                                sx={{ padding: "0px" }}
                                                size="small"
                                            />
                                            <label
                                                htmlFor="creditdebitcard"
                                                style={{
                                                    fontSize: "11px",
                                                    fontWeight: 500,
                                                }}
                                            >
                                                &nbsp;&nbsp;Credit Card/Debit
                                                Card
                                            </label>
                                        </div>
                                        <div style={{ marginTop: "10px" }}>
                                            <Checkbox
                                                id="wiretransfer"
                                                checked={
                                                    paymentMethod ==
                                                    "Wire Transfer/Proforma"
                                                }
                                                onChange={() =>
                                                    setPaymentMethod(
                                                        "Wire Transfer/Proforma"
                                                    )
                                                }
                                                sx={{ padding: "0px" }}
                                                size="small"
                                            />
                                            <label
                                                htmlFor="wiretransfer"
                                                style={{
                                                    fontSize: "11px",
                                                    fontWeight: 500,
                                                }}
                                            >
                                                &nbsp;&nbsp;Wire
                                                Transfer/Proforma
                                            </label>
                                        </div>
                                        {/* <div style={{ marginTop: "10px" }}>
                                            <Checkbox
                                                id="cod"
                                                checked={paymentMethod == "COD"}
                                                onChange={() =>
                                                    setPaymentMethod("COD")
                                                }
                                                sx={{ padding: "0px" }}
                                                size="small"
                                            />
                                            <label
                                                htmlFor="cod"
                                                style={{
                                                    fontSize: "11px",
                                                    fontWeight: 500,
                                                }}
                                            >
                                                &nbsp;&nbsp;COD
                                            </label>
                                        </div> */}
                                    </div>

                                    <Divider sx={{ marginTop: "1rem" }} />

                                    <div>
                                        <div style={{ marginTop: "10px" }}>
                                            <label className="purchaseorderlabel">
                                                Purchase Order Number:
                                            </label>
                                            <div>
                                                <input
                                                    placeholder="Optional"
                                                    className="purchaseorderinput"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    {/* <Divider sx={{ marginTop: "1rem" }} /> */}
                                </div>
                            )}

                            {orderStatus == 4 && (
                                <div>
                                    <CartHeading
                                        icon={<BiMenuAltLeft fontSize={18} />}
                                        heading={"Order Review"}
                                        caption={""}
                                    />

                                    <div style={{ marginLeft: "2.3rem" }}>
                                        <Grid container spacing={2}>
                                            <Grid item md={6}>
                                                <div className="orderreviewbox">
                                                    <div className="justifyspacebetween">
                                                        <div
                                                            style={{
                                                                fontSize:
                                                                    "11px",
                                                                color: "#1F80C0",
                                                                fontWeight: 600,
                                                            }}
                                                        >
                                                            BILLING INFORMATION
                                                        </div>
                                                        <div>
                                                            <BiSolidEdit
                                                                fontSize={14}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div
                                                        style={{
                                                            lineHeight: "14px",
                                                        }}
                                                    >
                                                        <div>
                                                            <span className="orderreviewcaption">
                                                                Summon
                                                                Electronics
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <span className="orderreviewcaption">
                                                                John Comunale
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <span className="orderreviewcaption">
                                                                John Comunale
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <span className="orderreviewcaption">
                                                                725 W State Rd
                                                                434 H, Longwood,
                                                                FL
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <span className="orderreviewcaption">
                                                                32750, United
                                                                States
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <span className="orderreviewcaption">
                                                                +1 321-284-8734
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Grid>

                                            <Grid item md={6}>
                                                <div className="orderreviewbox">
                                                    <div className="justifyspacebetween">
                                                        <div
                                                            style={{
                                                                fontSize:
                                                                    "11px",
                                                                color: "#1F80C0",
                                                                fontWeight: 600,
                                                            }}
                                                        >
                                                            BILLING INFORMATION
                                                        </div>
                                                        <div>
                                                            <BiSolidEdit
                                                                fontSize={14}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div
                                                        style={{
                                                            lineHeight: "14px",
                                                        }}
                                                    >
                                                        <div>
                                                            <span className="orderreviewcaption">
                                                                Summon
                                                                Electronics
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <span className="orderreviewcaption">
                                                                John Comunale
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <span className="orderreviewcaption">
                                                                John Comunale
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <span className="orderreviewcaption">
                                                                725 W State Rd
                                                                434 H, Longwood,
                                                                FL
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <span className="orderreviewcaption">
                                                                32750, United
                                                                States
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <span className="orderreviewcaption">
                                                                +1 321-284-8734
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Grid>

                                            <Grid item md={6}>
                                                <div className="orderreviewbox">
                                                    <div className="justifyspacebetween">
                                                        <div
                                                            style={{
                                                                fontSize:
                                                                    "11px",
                                                                color: "#1F80C0",
                                                                fontWeight: 600,
                                                            }}
                                                        >
                                                            SHIPPING METHOD
                                                        </div>
                                                        <div>
                                                            <BiSolidEdit
                                                                fontSize={14}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div
                                                        style={{
                                                            lineHeight: "14px",
                                                        }}
                                                    >
                                                        <div>
                                                            <span className="orderreviewcaption">
                                                                Shipment:
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <span className="orderreviewcaption">
                                                                UPS Ground
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Grid>

                                            <Grid item md={6}>
                                                <div className="orderreviewbox">
                                                    <div className="justifyspacebetween">
                                                        <div
                                                            style={{
                                                                fontSize:
                                                                    "11px",
                                                                color: "#1F80C0",
                                                                fontWeight: 600,
                                                            }}
                                                        >
                                                            PAYMENT METHOD
                                                        </div>
                                                        <div>
                                                            <BiSolidEdit
                                                                fontSize={14}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div
                                                        style={{
                                                            lineHeight: "14px",
                                                        }}
                                                    >
                                                        <div>
                                                            <span className="orderreviewcaption">
                                                                Payment Type:
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <span className="orderreviewcaption">
                                                                COD Requested
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Grid>

                                            <Grid item md={6}>
                                                <div className="orderreviewbox">
                                                    <div className="justifyspacebetween">
                                                        <div
                                                            style={{
                                                                fontSize:
                                                                    "11px",
                                                                color: "#1F80C0",
                                                                fontWeight: 600,
                                                            }}
                                                        >
                                                            SALES TAX
                                                            INFORMATION
                                                        </div>
                                                        <div>
                                                            <BiSolidEdit
                                                                fontSize={14}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div
                                                        style={{
                                                            lineHeight: "14px",
                                                        }}
                                                    >
                                                        <div>
                                                            <span className="orderreviewcaption">
                                                                Sales Tax:
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <span className="orderreviewcaption">
                                                                UPS Ground
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Grid>

                                            <Grid item md={6}>
                                                <div className="orderreviewbox">
                                                    <div className="justifyspacebetween">
                                                        <div
                                                            style={{
                                                                fontSize:
                                                                    "11px",
                                                                color: "#1F80C0",
                                                                fontWeight: 600,
                                                            }}
                                                        >
                                                            PRODUCT DETAIL
                                                        </div>
                                                        <div>
                                                            <BiSolidEdit
                                                                fontSize={14}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div
                                                        style={{
                                                            lineHeight: "14px",
                                                        }}
                                                    >
                                                        <div>
                                                            <span className="orderreviewcaption">
                                                                Description:
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <span className="orderreviewcaption">
                                                                Phone Connectors
                                                                3.5mm Narrow
                                                                Ftprint
                                                                3conductr Tip
                                                                switc
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Grid>

                                            <Grid item md={6}>
                                                <div className="orderreviewbox">
                                                    <div className="justifyspacebetween">
                                                        <div
                                                            style={{
                                                                fontSize:
                                                                    "11px",
                                                                color: "#1F80C0",
                                                                fontWeight: 600,
                                                            }}
                                                        >
                                                            PRODUCT INFORMATION
                                                        </div>
                                                        <div>
                                                            <BiSolidEdit
                                                                fontSize={14}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div
                                                        style={{
                                                            lineHeight: "14px",
                                                        }}
                                                    >
                                                        <div>
                                                            <span className="orderreviewcaption">
                                                                Mfr.:
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <span className="orderreviewcaption">
                                                                CUI Devices
                                                            </span>
                                                        </div>
                                                        <br />
                                                        <div>
                                                            <span className="orderreviewcaption">
                                                                Mfr.'s Part #:
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <span className="orderreviewcaption">
                                                                SJ2-35853B-SMT-TR
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Grid>

                                            <Grid item md={6}>
                                                <div className="orderreviewbox">
                                                    <div className="justifyspacebetween">
                                                        <div
                                                            style={{
                                                                fontSize:
                                                                    "11px",
                                                                color: "#1F80C0",
                                                                fontWeight: 600,
                                                            }}
                                                        >
                                                            OTHERS
                                                        </div>
                                                        <div>
                                                            <BiSolidEdit
                                                                fontSize={14}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div
                                                        style={{
                                                            lineHeight: "14px",
                                                        }}
                                                    >
                                                        <Grid
                                                            container
                                                            spacing={1}
                                                        >
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                md={6}
                                                            >
                                                                <div>
                                                                    <span className="orderreviewcaption">
                                                                        Quantity:
                                                                    </span>
                                                                </div>
                                                                <div>
                                                                    <span className="orderreviewcaption">
                                                                        1,000
                                                                    </span>
                                                                </div>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                md={6}
                                                            >
                                                                <div>
                                                                    <span className="orderreviewcaption">
                                                                        Availability:
                                                                    </span>
                                                                </div>
                                                                <div>
                                                                    <span className="orderreviewcaption">
                                                                        1,000
                                                                    </span>
                                                                </div>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                md={6}
                                                            >
                                                                <div>
                                                                    <span className="orderreviewcaption">
                                                                        Price
                                                                        (Unit):
                                                                    </span>
                                                                </div>
                                                                <div>
                                                                    <span className="orderreviewcaption">
                                                                        $0.504
                                                                    </span>
                                                                </div>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                md={6}
                                                            >
                                                                <div>
                                                                    <span className="orderreviewcaption">
                                                                        Price
                                                                        (Ext
                                                                        Total):
                                                                    </span>
                                                                </div>
                                                                <div>
                                                                    <span className="orderreviewcaption">
                                                                        $504.00
                                                                    </span>
                                                                </div>
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </div>
                            )}
                        </Grid>

                        <Grid item xs={12} md={5}>
                            <CartHeading
                                icon={<HiDocumentText fontSize={14} />}
                                heading={"Order Summary"}
                                caption={"01 item"}
                            />

                            <hr style={{ marginTop: "2.2rem" }} />

                            <div>
                                <div className="ordersummaryitems">
                                    <div>
                                        <span style={{ fontSize: "13px" }}>
                                            Sub Total:
                                        </span>
                                    </div>
                                    <div>
                                        <span
                                            style={{
                                                fontSize: "13px",
                                                fontWeight: "bold",
                                            }}
                                        >
                                            $
                                            {parseFloat(
                                                merchandise
                                            ).toLocaleString("en-US", {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                            })}
                                        </span>
                                    </div>
                                </div>
                                {orderStatus > 0 && (
                                    <>
                                        <div className="ordersummaryitems">
                                            <div>
                                                <span
                                                    style={{ fontSize: "13px" }}
                                                >
                                                    Shipping Charge:
                                                </span>
                                            </div>
                                            <div>
                                                <span
                                                    style={{
                                                        fontSize: "13px",
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    $
                                                    {selectedOption?.totalCharge.toLocaleString(
                                                        "en-US",
                                                        {
                                                            minimumFractionDigits: 2,
                                                            maximumFractionDigits: 2,
                                                        }
                                                    ) || 0}
                                                </span>
                                            </div>
                                        </div>
                                        {orderStatus > 1 && (
                                            <div className="ordersummaryitems">
                                                <div>
                                                    <span
                                                        style={{
                                                            fontSize: "13px",
                                                        }}
                                                    >
                                                        Estimated Sales Tax:
                                                    </span>
                                                </div>
                                                <div>
                                                    <span
                                                        style={{
                                                            fontSize: "13px",
                                                            fontWeight: "bold",
                                                        }}
                                                    >
                                                        $
                                                        {salesTax.toLocaleString(
                                                            "en-US",
                                                            {
                                                                minimumFractionDigits: 2,
                                                                maximumFractionDigits: 2,
                                                            }
                                                        )}
                                                    </span>
                                                </div>
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>

                            <hr style={{ marginTop: "1rem" }} />

                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginTop: "10px",
                                    justifyContent: "space-between",
                                }}
                            >
                                <div>
                                    <span
                                        style={{
                                            color: "#1F80C0",
                                            fontWeight: 500,
                                            fontSize: "16px",
                                        }}
                                    >
                                        Order Total:
                                    </span>
                                </div>
                                <div>
                                    <span
                                        style={{
                                            color: "#1F80C0",
                                            fontSize: "16px",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        {orderStatus == 0 &&
                                            `$${parseFloat(
                                                merchandise
                                            ).toLocaleString("en-US", {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                            })}`}
                                        {orderStatus == 1 &&
                                            `$${(
                                                parseFloat(merchandise) +
                                                parseFloat(
                                                    selectedOption?.totalCharge ||
                                                        0
                                                )
                                            ).toLocaleString("en-US", {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                            })}`}
                                        {orderStatus == 2 &&
                                            `$${(
                                                parseFloat(merchandise) +
                                                parseFloat(
                                                    selectedOption?.totalCharge ||
                                                        0
                                                ) +
                                                parseFloat(salesTax || 0)
                                            ).toLocaleString("en-US", {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                            })}`}
                                    </span>
                                </div>
                            </div>

                            {orderStatus != 0 && (
                                <>
                                    <hr style={{ marginTop: "1rem" }} />

                                    <div style={{ marginTop: "10px" }}>
                                        <span style={{ fontSize: "10px" }}>
                                            Sales Tax and Order Total amounts
                                            are estimates. Actual totals will be
                                            determined at time of shipment.
                                        </span>
                                    </div>
                                </>
                            )}
                        </Grid>
                    </Grid>

                    <hr style={{ marginTop: "1rem" }} />

                    {orderStatus == 0 && (
                        <Grid
                            container
                            sx={{ marginBlock: "10px" }}
                            spacing={2}
                        >
                            <Grid item md={6}>
                                <span style={{ fontSize: "10px" }}>
                                    By submitting your order you agree to
                                    these&nbsp;
                                    <span
                                        style={{
                                            color: "#1F80C0",
                                            fontWeight: 500,
                                        }}
                                    >
                                        terms and conditions
                                    </span>
                                    . **All items are non-cancellable and
                                    non-returnable.
                                </span>
                            </Grid>

                            <Grid item md={6}>
                                <div
                                    style={{
                                        display: "flex",
                                        gap: "1rem",
                                        justifyContent: "end",
                                    }}
                                >
                                    <Button
                                        onClick={handleBackToPrevBtn}
                                        variant="contained"
                                        color="secondary"
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <GrLinkPrevious />
                                        &nbsp;BACK
                                    </Button>
                                    <Button
                                        onClick={handleContinueBtn}
                                        variant="contained"
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        CONTINUE &nbsp; <GrLinkNext />
                                    </Button>
                                </div>
                            </Grid>
                        </Grid>
                    )}

                    {orderStatus == 1 && (
                        <Grid
                            container
                            sx={{ marginBlock: "10px" }}
                            spacing={2}
                        >
                            <Grid item md={6}>
                                <span style={{ fontSize: "10px" }}>
                                    By submitting your order you agree to
                                    these&nbsp;
                                    <span
                                        style={{
                                            color: "#1F80C0",
                                            fontWeight: 500,
                                        }}
                                    >
                                        terms and conditions
                                    </span>
                                    . **All items are non-cancellable and
                                    non-returnable.
                                </span>
                            </Grid>

                            <Grid item md={6}>
                                <div
                                    style={{
                                        display: "flex",
                                        gap: "1rem",
                                        justifyContent: "end",
                                    }}
                                >
                                    <Button
                                        onClick={handleBackToPrevBtn}
                                        variant="contained"
                                        color="secondary"
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <GrLinkPrevious />
                                        &nbsp;BACK
                                    </Button>
                                    <Button
                                        onClick={handleShippingContinueBtn}
                                        variant="contained"
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        CONTINUE &nbsp; <GrLinkNext />
                                    </Button>
                                </div>
                                {error !== null && (
                                    <p
                                        className="errormessage"
                                        style={{ textAlign: "end" }}
                                    >
                                        {error}
                                    </p>
                                )}
                            </Grid>
                        </Grid>
                    )}

                    {orderStatus == 2 && (
                        <Grid
                            container
                            sx={{ marginBlock: "10px" }}
                            spacing={2}
                        >
                            <Grid item md={6}>
                                <span style={{ fontSize: "10px" }}>
                                    By submitting your order you agree to
                                    these&nbsp;
                                    <span
                                        style={{
                                            color: "#1F80C0",
                                            fontWeight: 500,
                                        }}
                                    >
                                        terms and conditions
                                    </span>
                                    . **All items are non-cancellable and
                                    non-returnable.
                                </span>
                            </Grid>

                            <Grid item md={6}>
                                <div
                                    style={{
                                        display: "flex",
                                        gap: "1rem",
                                        justifyContent: "end",
                                    }}
                                >
                                    <Button
                                        onClick={handleBackToPrevBtn}
                                        variant="contained"
                                        color="secondary"
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        <GrLinkPrevious />
                                        &nbsp;BACK
                                    </Button>
                                    <Button
                                        onClick={makePayment}
                                        variant="contained"
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        CONTINUE &nbsp; <GrLinkNext />
                                    </Button>
                                </div>
                                {error !== null && (
                                    <p
                                        className="errormessage"
                                        style={{ textAlign: "end" }}
                                    >
                                        {error}
                                    </p>
                                )}
                            </Grid>
                        </Grid>
                    )}
                </div>
            </div>
        </div>
    );
}
