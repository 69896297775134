import React, { useState } from "react";
import logo from "../Assets/Images/logo.png";
import {
    Button,
    Checkbox,
    FormControl,
    MenuItem,
    Select,
    Typography,
} from "@mui/material";
import { TextField, InputAdornment } from "@mui/material";
import { styled } from "@mui/system";
import { IoSearch } from "react-icons/io5"; // Or any other icon you prefer
import { IoIosLock } from "react-icons/io";
import { FaUser } from "react-icons/fa";
import element from "../Assets/Images/element.png";
import { MdEmail } from "react-icons/md";
import { FaRegCircle } from "react-icons/fa";
import { FaMobileAlt } from "react-icons/fa";
import { FaCircle } from "react-icons/fa";
import "./home.css";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../Constant_key";
import axios from "axios";
import { toast } from "react-toastify";
import { LiaIndustrySolid } from "react-icons/lia";

const VerticalDivider = styled("div")(({ theme }) => ({
    width: 3,
    height: 20,
    margin: theme.spacing(0.5, 0.5),
    backgroundColor: theme.palette.divider,
}));

export default function Register() {
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        name: "",
        mobile: "",
        email: "",
        password: "",
    });

    const [name, setName] = useState("");
    const [lastName, setLastName] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [mobile, setMobile] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [errors, setErrors] = useState({});
    const [termsAccepted, setTermsAccepted] = useState(false);

    const validateForm = () => {
        let tempErrors = {};
        tempErrors.name = name ? "" : "First Name is required";
        tempErrors.lastName = lastName ? "" : "Last Name is required";
        tempErrors.companyName = companyName ? "" : "Company Name is required";
        tempErrors.mobile = /^[0-9]{10}$/.test(mobile)
            ? ""
            : "Enter a valid 10-digit mobile number";
        tempErrors.email = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
            ? ""
            : "Enter a valid email address";
        tempErrors.password =
            /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{10,})/.test(password)
                ? ""
                : "Password: 10+ chars, 1 capital, 1 number, 1 symbol";
        tempErrors.terms = termsAccepted
            ? ""
            : "You must accept the Terms & Conditions";

        setErrors(tempErrors);
        return Object.values(tempErrors).every((x) => x === "");
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            // Proceed with registration
            const value = {
                first_name: name,
                last_name: lastName,
                email: email,
                password: password,
                phone_number: mobile,
                company_name: companyName,
                websiteSource: "rfq genie"
            };
            axios
                .post(`${baseUrl}api/register`, value)
                .then((res) => {
                    if (res?.data?.status == 105) {
                        toast.success(res?.data?.message);
                        navigate("/verification", {state: {email}});
                    }
                })
                .catch((err) => {
                    toast.error(err?.response?.data?.message);
                    // console.log("err", err);
                });
        } else {
            // console.log("Form is invalid");
        }
    };

    const CustomSelect = styled(Select)(({ theme }) => ({
        "& .MuiSelect-select": {
            fontSize: "0.875rem",
            padding: "6px 8px",
            height: "10px",
        },
        "& .MuiOutlinedInput-notchedOutline": {
            // This matches the border style of the TextField
            borderColor:
                theme.palette.mode === "light"
                    ? "rgba(0, 0, 0, 0.23)"
                    : "rgba(255, 255, 255, 0.23)",
        },
    }));

    const CustomMenuItem = styled(MenuItem)(({ theme }) => ({
        fontSize: "0.875rem",
    }));

    return (
        <>
            <div>
                <div
                    style={{
                        display: "flex",
                        position: "absolute",
                        top: "50%",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                    }}
                >
                    <img className="loginelementimage" src={element} />
                    <img
                        className="loginelementimage"
                        style={{ transform: "rotate(180deg)" }}
                        src={element}
                    />
                </div>
            </div>
            <div
                style={{
                    display: "flex",
                    height: "100vh",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <img
                    className="loginPageMainLogo"
                    src={logo}
                />
                <Typography color="primary" className="registerwelcome">
                    Welcome!
                </Typography>
                <Typography className="registertext">
                    Register to get your own Connector Genie.
                </Typography>

                <form
                    onSubmit={handleSubmit}
                    style={{ display: "flex", alignItems:'center', flexDirection: "column" }}
                >
                    <TextField
                        name="name"
                        className="custom-text-field"
                        placeholder="First Name"
                        autoComplete="off"
                        sx={{ marginTop: "2rem" }}
                        value={name}
                        onChange={(e) => {
                            setErrors((prev) => ({ ...prev, name: "" }));
                            setName(e.target.value);
                        }}
                        error={!!errors.name}
                        // helperText={errors.name}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <FaUser
                                        style={{
                                            marginLeft: "4px",
                                            marginRight: "6px",
                                        }}
                                    />
                                    <VerticalDivider />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <p className="errormessage">{errors.name}</p>

                    <TextField
                        name="lastName"
                        className="custom-text-field"
                        placeholder="Last Name"
                        autoComplete="off"
                        sx={{ marginTop: "1rem" }}
                        value={lastName}
                        onChange={(e) => {
                            setErrors((prev) => ({ ...prev, lastName: "" }));
                            setLastName(e.target.value);
                        }}
                        error={!!errors.lastName}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <FaUser
                                        style={{
                                            marginLeft: "4px",
                                            marginRight: "6px",
                                        }}
                                    />
                                    <VerticalDivider />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <p className="errormessage">{errors.lastName}</p>

                    <div
                        style={{
                            display: "flex",
                            marginTop: "1rem",
                            alignItems: "center",
                        }}
                    >
                        {/* <CustomSelect
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            // value={phoneCode}
                                            // onChange={(e) => setPhoneCode(e.target.value)}
                                            // value={age}
                                            // onChange={handleChange}
                                        >
                                            <CustomMenuItem value={1}>
                                                +1
                                            </CustomMenuItem>
                                        </CustomSelect> */}
                        <TextField
                            name="mobile"
                            className="custom-text-field"
                            placeholder="Mobile No"
                            type="number"
                            autoComplete="off"
                            value={mobile}
                            onChange={(e) => {
                                setErrors((prev) => ({ ...prev, mobile: "" }));
                                setMobile(e.target.value);
                            }}
                            error={!!errors.mobile}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <span
                                            style={{
                                                fontSize: "1.05rem",
                                                marginRight: "4px",
                                            }}
                                        >
                                            +1
                                        </span>
                                        {/* <FaMobileAlt
                                        
                                    /> */}
                                        <VerticalDivider />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </div>
                    <p className="errormessage">{errors.mobile}</p>

                    <TextField
                        name="companyName"
                        className="custom-text-field"
                        placeholder="Company Name"
                        autoComplete="off"
                        sx={{ marginTop: "1rem" }}
                        value={companyName}
                        onChange={(e) => {
                            setErrors((prev) => ({ ...prev, companyName: "" }));
                            setCompanyName(e.target.value);
                        }}
                        error={!!errors.companyName}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <LiaIndustrySolid
                                        style={{
                                            fontSize: "1.2rem",
                                            marginRight: "4px",
                                        }}
                                    />
                                    <VerticalDivider />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <p className="errormessage">{errors.companyName}</p>

                    <TextField
                        name="email"
                        placeholder="Email"
                        className="custom-text-field"
                        autoComplete="off"
                        sx={{ marginTop: "1rem" }}
                        value={email}
                        onChange={(e) => {
                            setErrors((prev) => ({ ...prev, email: "" }));
                            setEmail(e.target.value);
                        }}
                        error={!!errors.email}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <MdEmail
                                        style={{
                                            fontSize: "1.2rem",
                                            marginRight: "4px",
                                        }}
                                    />
                                    <VerticalDivider />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <p className="errormessage">{errors.email}</p>

                    <TextField
                        name="password"
                        type="password"
                        placeholder="Password"
                        className="custom-text-field"
                        sx={{ marginTop: "1rem" }}
                        value={password}
                        onChange={(e) => {
                            setErrors((prev) => ({ ...prev, password: "" }));
                            setPassword(e.target.value);
                        }}
                        error={!!errors.password}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <IoIosLock
                                        style={{
                                            fontSize: "1.2rem",
                                            marginRight: "4px",
                                        }}
                                    />
                                    <VerticalDivider />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <p className="errormessage">{errors.password}</p>

                    <div className="registerPageBottomCondition">
                        <Checkbox
                            id="tnc"
                            sx={{ paddingInline: "0px" }}
                            size="small"
                            icon={<FaRegCircle />}
                            checkedIcon={<FaCircle />}
                            checked={termsAccepted}
                            onChange={(e) => setTermsAccepted(e.target.checked)}
                        />
                        <label htmlFor="tnc" className="loginbottomoptions">
                            &nbsp;I've read and agree to{" "}
                            <span className="loginbottomcoloredoptions">
                                Terms & Condition
                            </span>
                        </label>
                    </div>
                    {errors.terms && (
                        <p className="errormessage">{errors.terms}</p>
                    )}

                    <Button className="loginBtn" type="submit">
                        REGISTER
                    </Button>
                </form>

                <label
                    onClick={() => navigate("/login")}
                    htmlFor="tnc"
                    style={{ marginTop: "1rem" }}
                    className="loginbottomoptions"
                >
                    Already have an account?
                    <span className="loginbottomcoloredoptions">
                        &nbsp;Login&nbsp;
                    </span>
                    instead.
                </label>
            </div>
        </>
    );
}
