import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import logo from '../Assets/Images/logo.png'

const NotFoundContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f8f9fa;
    font-family: Arial, sans-serif;
`;

const NotFoundTitle = styled.h1`
    font-size: 8rem;
    color: #343a40;
    margin-bottom: 0;
`;

const NotFoundText = styled.p`
    font-size: 2rem;
    color: #6c757d;
    margin-top: 0;
`;

const NotFoundLink = styled(Link)`
    margin-top: 2rem;
    padding: 1rem 2rem;
    background-color: #007bff;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    font-size: 1.2rem;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #0056b3;
    }
`;

const NotFoundImage = styled.img`
    width: 300px;
    margin-bottom: 2rem;
`;

const NotFound = () => {
    return (
        <NotFoundContainer>
            <NotFoundImage
                src={logo}
                alt="404 Illustration"
            />
            <NotFoundTitle>404</NotFoundTitle>
            <NotFoundText>Oops! Page not found</NotFoundText>
            <NotFoundLink to="/">Go back to homepage</NotFoundLink>
        </NotFoundContainer>
    );
};

export default NotFound;
